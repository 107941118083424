// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

// import Image from "../components/image"

const Header = ({ siteTitle }) => (
  <header
  className="mb-auto"
  >
    <div>
      <h3 className="float-md-start mb-0">{siteTitle}</h3>
      {/* <nav className="nav nav-masthead justify-content-center float-md-end">
        <a className="nav-link active" aria-current="page" href="/">Home</a>
        <a className="nav-link" href="/">Features</a>
        <a className="nav-link" href="/">Contact</a>
      </nav> */}
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `kraftgamehaus`,
}

export default Header
