/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

import Image from "../components/image"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        <main className="px-3">
          <Image/>
          <h1>Coming Soon...</h1>
          <p className="lead">An indie game studio working hard on our first title.</p>
          <form id="email-form" name="contact" data-netlify="true" method="post">
            <div className="mb-3">
              <input type="hidden" name="form-name" value="contact" />
              <input type="email" name="email" className="form-control" aria-describedby="emailHelp" placeholder="email for more info..." />
              <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
            </div>
            <button type="submit" className="btn btn-secondary">Submit</button>
          </form>
          <p id="response"></p>
        </main>
        <footer className="mt-auto text-white-50">
          <p>© {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.com">Gatsby</a></p>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
